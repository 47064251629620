import React, { useState, useRef, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@react-hook/media-query';

import style from './FilterSelect.module.scss';

const FilterSelect = ({ type, title, data, handleSelect, selectedFilters }) => {
    const compRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        function outsideClick (e) {
            if (!compRef.current.contains(e.target)) {
                setIsOpen(false);
            }
        }
        window.addEventListener('click', outsideClick);
        return () => {
            window.removeEventListener('click', outsideClick);
        };
    });

    const isDesktop = useMediaQuery('only screen and (min-width: 768px)');

    const handleGroupSelect = e => {
        const group = `group-${e.target.value}`;
        const checked = e.target.checked;
        Array.from(document.getElementsByClassName(group)).forEach(el => {
            if (checked && !el.checked) {
                el.click();
            }
            if (!checked && el.checked) {
                el.click();
            }
        });
    };

    useEffect(() => {
        if (isDesktop) {
            setIsOpen(false);
        }
    }, [isDesktop]);

    const renderChildOptions = (data, group) => {
        return (
            <div className={style.subOptions}>
                {data.map(option => {
                    const isChecked = selectedFilters.some(f => f.value === option.value);
                    return (
                        <div key={option.value} className={style.optionItem}>
                            <label className='pure-material-checkbox'>
                                <input
                                    type='checkbox'
                                    name={type}
                                    id={`${type}-${option.value}`}
                                    onChange={handleSelect}
                                    value={option.value}
                                    data-label={option.label}
                                    checked={isChecked}
                                    className={`group-${group}`}
                                />
                                <span>{option.label}</span>
                            </label>
                        </div>
                    );
                })}
            </div>
        );
    };

    const sumHeight = arr => {
        return arr.reduce((a, b) => (a | 0) + (b | 51)) + 51;
    };

    let childrenHeight = 0;

    const minHeight = data.reduce((a, b) => {
        if (b.children && b.children.length > 0) {
            childrenHeight += sumHeight(b.children);
        }
        return sumHeight(data) + childrenHeight + 20;
    });

    return (
        <div className={style.el} ref={compRef}>
            <button type='button' className={style.button} onClick={toggleOpen}>
                <span className={style.title}>{title}</span>
                <span className={[style.icon, isOpen ? style.open : '', 'icon-arrow-up'].join(' ')} />
            </button>
            <div
                className={[style.options, isOpen ? style.active : style.closed].join(' ')}
                style={{ '--var-mh': `${minHeight}px` }}
            >
                <div className={style.optionsWrap}>
                    {data.map(option => {
                        const isChecked =
                            option.value === 'all'
                                ? selectedFilters.length <= 0
                                : selectedFilters.some(f => f.value === option.value);
                        const hasChildren = option.children && option.children.length > 0;
                        return (
                            <Fragment key={option.value}>
                                <div className={style.optionItem}>
                                    <label className='pure-material-checkbox'>
                                        {hasChildren ? (
                                            <input
                                                type='checkbox'
                                                name={type}
                                                id={`${type}-${option.value}`}
                                                onChange={handleGroupSelect}
                                                value={option.value}
                                                data-label={option.label}
                                            />
                                        ) : (
                                            <input
                                                type='checkbox'
                                                name={type}
                                                id={`${type}-${option.value}`}
                                                onChange={handleSelect}
                                                value={option.value}
                                                data-label={option.label}
                                                checked={isChecked}
                                            />
                                        )}
                                        <span>{option.label}</span>
                                    </label>
                                </div>
                                {hasChildren ? renderChildOptions(option.children, option.value) : null}
                            </Fragment>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

FilterSelect.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.array,
    handleSelect: PropTypes.func,
    selectedFilters: PropTypes.array,
};

export default FilterSelect;
