import React from 'react';
import PropTypes from 'prop-types';

import PageSelect from './PageSelect';

import style from './Pagination.module.scss';

const Pagination = ({ total, active, changePage }) => {
    const handlePrevPage = () => {
        changePage(active - 1);
    };

    const handleNextPage = () => {
        changePage(active + 1);
    };

    const handleSelectChange = e => {
        const val = typeof e === 'number' ? e : e.target.value;
        changePage(val);
    };

    return (
        <div className={style.el}>
            <div className={style.pageSelect}>
                <PageSelect active={active} total={total} handleSelect={handleSelectChange} />
            </div>
            <div className={style.pageTotal}>
                {active + 1} of {total}
            </div>
            <button
                type='button'
                onClick={handlePrevPage}
                className={[style.button, style.prevButton, active === 0 ? style.disabled : ''].join(' ')}
            >
                <span className='icon-arrow-right' />
            </button>
            <button
                type='button'
                onClick={handleNextPage}
                className={[style.button, style.nextButton, active >= total - 1 ? style.disabled : ''].join(' ')}
            >
                <span className='icon-arrow-right' />
            </button>
        </div>
    );
};

Pagination.propTypes = {
    total: PropTypes.number,
    active: PropTypes.number,
    changePage: PropTypes.func,
};

export default Pagination;
